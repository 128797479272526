import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient) { }

  private auth: string = environment.apiUrl + '/auth';

  postLogin(userPass : any) {
    return this.http.post(`${ this.auth }/login/`,userPass);
  }

  getCheck() {
    return this.http.get(`${ this.auth }/check/`);
  }

}
