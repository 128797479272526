import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, NavigationExtras, Route, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

import { Router } from '@angular/router';

import { AuthService } from '../auth/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class TokenValidatorGuard implements CanActivate, CanLoad {
  
  constructor (private router: Router, private authservice: AuthService) { }
  
  canActivate(): Observable<boolean> | boolean {
    if (localStorage.getItem('token')) {
      this.authservice.getCheck().subscribe(resp => {
        // console.log("GUARD: ", resp);
      });
      return true;
    } else {
      const navigationExtras: NavigationExtras = { state: { invalidToken: true } };
      this.router.navigate(['/auth/login'],navigationExtras);
    }
    return false;
  }
  
  canLoad(): Observable<boolean> | boolean {
    if (localStorage.getItem('token')) {
      return true;
    } else {
      const navigationExtras: NavigationExtras = { state: { invalidToken: true } };
      this.router.navigate(['/auth/login'],navigationExtras);
    }
    return false;
  }

}
