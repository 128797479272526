import {Injectable, Injector} from '@angular/core';

import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Router, NavigationExtras } from '@angular/router'

import { NgxRolesService } from 'ngx-permissions';

import {AuthService} from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {

  constructor(private injector: Injector, private router: Router, private roleServices: NgxRolesService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const auth = this.injector.get(AuthService); 
        
    const token = localStorage.getItem('token');
        
    request = request.clone({
      setHeaders: {
        token: String(token)
      }
    });

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMsg = 'Interceptor: Error en petición:' + "\n\n" + JSON.stringify(error, null, 2);
        // if (error.error instanceof ErrorEvent) {
        //     console.log('This is client side error');
        //     errorMsg = `Error: ${error.error.message}`;
        // } else {
        //     console.log('This is server side error');
        //     errorMsg = `Error Code: ${error.status},  Message: ${error.message}`;
        // }
        // console.log(errorMsg);
        const err = new Error(errorMsg); 
        const navigationExtras: NavigationExtras = { state: { invalidToken: true } };
        this.router.navigate(['/auth/login'], navigationExtras);
        return throwError(() => err);
      }),
      map((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        const { invalidToken } = event.body;
        const navigationExtras: NavigationExtras = { state: { invalidToken: true } };
        if (invalidToken) this.router.navigate(['/auth/login'], navigationExtras);

        // if (event.body._session) {
        //   // this.roleServices.flushRolesAndPermissions();
        //   const role = JSON.stringify(event.body?._session?.role?.codigo || '');
        //   const permissions = JSON.stringify( event.body?._session?.role?.settingsPermissions.map((permission:any) => { return permission.codigo }));
        //   console.log(role);
        //   console.log(permissions);
        //   this.roleServices.addRoleWithPermissions( JSON.parse( role || '' ) , JSON.parse( permissions || '' ) );
        // }
        

      }
      return event;
      })
    );

    // return next.handle(request).pipe(
    //   tap(
    //     event => { 
    //       if (event instanceof HttpResponse) {
    //         const { invalidToken } = event.body
    //         if (invalidToken) this.router.navigate(['/auth']);
    //       }            
    //     },
    //     error => {
    //       if (error instanceof HttpErrorResponse) {
    //         console.log('token-interceptor: ', error);
    //       }
    //     }
    //   ));

  }
}
